<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512.003 512.003"
    style="enable-background:new 0 0 512.003 512.003;"
    xml:space="preserve"
  >
    <circle style="fill:#FED159;" cx="256.001" cy="256.001" r="256.001" />
    <path
      style="fill:#F64B4A;"
      d="M511.989,256.001H86.053L256,0C397.38,0,511.989,114.622,511.989,256.001z"
    />
    <path
      style="fill:#65B4BB;"
      d="M256.005,0.013v255.989L74.982,437.024c-99.963-99.976-99.963-262.07,0-362.033
	C124.976,24.997,190.491,0.013,256.005,0.013z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
